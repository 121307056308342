
export const WIDGET_VERSIONS = {
  BOOK_ONLINE_UNIFIED: 'BOOK_ONLINE_UNIFIED',
  BOOK_ONLINE_V4: 'BOOK_ONLINE_V4', // V4
  APPOINTMENT_REQUEST: 'APPOINTMENT_REQUEST', // V3
  BOOK_ONLINE_V2: 'BOOK_ONLINE_V2' // V2
}

export const MODE_PARAM_MAPPING = {
  APPOINTMENT_REQUEST: 'appointment-request',
  BOOK_ONLINE_V2: 'book-online'
}

export const STORAGE_CONFIG_ITEM_NAME = 'ppBookConfig'

export const FALLBACK_WIDGET_VERSION = WIDGET_VERSIONS.APPOINTMENT_REQUEST
export const WIDGET_CONFIGURATION_KEY = 'pp-widget-params-c94c6f5b-e23a-44fa-94f6-759bf4ad676d'

export const ORIGIN_CARE_CONNECT = 'tcm'
export const ORIGIN_PRACTICE_KAREO = 'practice.kareo.com'

export const PG_PRACTICE_UUID_FORCE_USE_PO = {
  // PG Practice UUID : PO KID
  '11709f38-06b5-463b-9396-cbda29ba6260': 'k_1_57310',
  '560a246f-bb01-4368-9160-33086f4edd61': 'k_1_96790' // Pacific Oaks Medical Group
}
