import getConfig from '@/utils/getConfig'
const gatherWidgetConfig = () => {
  const currentConfig = getConfig()

  return {
    practiceKey: currentConfig.get('practiceKey'),
    confirmParam: currentConfig.get('confirm'),
    notWidgetManagerUsage: Boolean(currentConfig.get('notWidgetManagerUsage')), // true if widget is going to be used without widgetManager
    practiceId: currentConfig.get('practiceId'),
    practiceUuid: currentConfig.get('practiceUuid'),
    originHost: currentConfig.get('originHost'),
    gaId: currentConfig.get('gaId'),
    locationId: currentConfig.get('locationId'),
    providerId: currentConfig.get('providerId'),
    appointmentTypeId: currentConfig.get('appointmentTypeId'),
    origin: currentConfig.get('origin'),
    showCloseButton: Boolean(+currentConfig.get('showCloseButton'))
  }
}
const config = gatherWidgetConfig()
const showConfirmModal = config.confirmParam && config.confirmParam.length > 0
if (showConfirmModal) {
  // lazy load for ui with Vue
  const loadModalUi = () => import('@modal-src')
  loadModalUi().then((modalModule) => {
    modalModule.default(config)
  })
} else {
  // lazy load for widget-loader
  const getLoader = () => import('@loader-src')
  getLoader().then((widgetLoader) => {
    widgetLoader.default(config)
  })
}
