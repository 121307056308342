import { WIDGET_CONFIGURATION_KEY } from '@loader-src/constants'

export default () => {
  let ppBookConfig = {}
  try {
    if (typeof window.localStorage !== 'undefined') {
      ppBookConfig = JSON.parse(window.localStorage.getItem(WIDGET_CONFIGURATION_KEY)) || {}
    } else {
      console.log('localStorage is not supported')
    }
  } catch (e) {
    console.log(e)
  }
  const urlParams = new URLSearchParams(window.location.search)
  return {
    get: (field) => {
      const value = urlParams.get(field) || ppBookConfig[field]
      return value !== null ? value : undefined
    }
  }
}
